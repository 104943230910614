.capitalize {
    text-transform: capitalize;
}

div#shop {
    margin-top: 22vh;
}

@media (max-width: 767px) {
    div#shop {
        margin-top: 3vh;
    }
}

#shop #search {
    background-color: #2b303a;
    color: #ffffff;
    margin: 0px 0 40px;
}

#shop #search label {
    font-weight: 100;
}

#shop #search input,
#shop #search textarea,
#shop #search select {
    width: 100%;
    background-color: rgba(255, 255, 255, 0.4);
    border: none;
    padding: 12px 10px;
}

#shop #search input:focus,
#shop #search textarea:focus,
#shop #search select:focus {
    outline: 0;
    background-color: #ffffff;
    color: #0b171e;
}

#shop #search input,
#shop #search select {
    height: 49px;
}

#shop #search .form-group {
    position: relative;
    margin-top: 15px;
}

#shop #search .form-group .input-group-icon {
    position: absolute;
    right: 0px;
    top: 0px;
    background-color: rgba(255, 255, 255, 0.6);
    height: 49px;
}

#shop #search .form-group .input-group-icon i {
    padding: 12px 14px;
    font-size: 2rem;
}

#shop #search .cart {
    margin-top: 30px;
    color: white;
    margin-bottom: 15px;
}

#shop #search .cart a {
    float: right !important;
    margin-left: 10px;
    color: white;
}

#shop #search .cart i {
    float: right !important;
}

@media (max-width: 767px) {
    #shop #search .cart {
        margin-top: 0;
        color: white;
        margin-bottom: 15px;
    }
}

#shop #cart .btn, button {
    color: #fff;
    border-radius: 0;
    box-shadow: none !important;
    padding: 10px 20px;
    margin: 0 0 10px !important;
}

#shop #cart .btn-danger {
    background-color: #af4949 !important;
}

#shop #cart .cart-quantity {
    display: inline-flex;
}

#shop #cart .cart-quantity span {
    padding-top: 5px;
}

#shop #cart .cart-quantity button {
    padding: 0px 13px;
    margin: 0 10px 0 0 !important;
    font-size: 18px;
}

#shop #item-detail .cart{
    margin-bottom: 10px;
    text-align: center;
}

#shop #item-detail .detail-title {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
}

#shop #item-detail .btn-danger {
    background-color: #af4949 !important;
}

#shop #item-detail .description {
    margin-top: 20px;
    margin-bottom: 20px
}

#shop #item-detail .well {
    padding: 0;
    margin-bottom: 0;
    border-radius: 0;
}

#shop #item-detail .well p {
    margin: 5px;
}

#shop #cart #accordion .field-error {
    color:#a94442;
}

#shop #cart .text-info{
    color: #000000;
}

#shop #cart .switch-field {
    margin-bottom: 20px;
    display: flex;
}
#shop #cart .switch-field input {
    display: none;
}
#shop #cart .switch-field label {
    float: left;
}
#shop #cart .switch-field label {
    display: inline-block;
    width: 100%;
    background-color: #e4e4e4;
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
    font-weight: normal;
    text-align: center;
    text-shadow: none;
    padding: 6px 14px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
    -webkit-transition: all 0.1s ease-in-out;
    -moz-transition: all 0.1s ease-in-out;
    -ms-transition: all 0.1s ease-in-out;
    -o-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
}
#shop #cart .switch-field label:hover {
    cursor: pointer;
}
#shop #cart .switch-field input:checked + label {
    background-color: #00804d;
    -webkit-box-shadow: none;
    box-shadow: none;
    color:white;
}
#shop #cart .switch-field label:first-of-type {
    border-radius: 4px 0 0 4px;
}
#shop #cart .switch-field label:last-of-type {
    border-radius: 0 4px 4px 0;
}


.spinner-container {
    top: 0;
    position: fixed;
    z-index: 200;
    width: 100%;
    height: 110%;
    display: none;
    background-color: rgba(255, 255, 255, 0.8);
}
.spinner {
    display: block;
    margin-top: 2em;
    margin-right: auto;
    margin-left: auto;
    width: 4em;
    height: 4em;
    padding: 7px;
    border: 1px solid #ccc;
    border-radius: 50%;
    transform: scale(0.7);
}
.medium {
    transform: scale(1);
}
.large {
    margin-top: 4em;
    transform: scale(1.5);
}
.spinner-wrapper {
    margin-top: 50vh;
    position: relative;
    width: 4em;
    height: 4em;
    border-radius: 100%;
    left: calc(48%);
}
.spinner-wrapper::after {
    content: "";
    background: #fff;
    border-radius: 50%;
    width: 3em;
    height: 3em;
    position: absolute;
    top: 0.5em;
    left: 0.5em;
}
.rotator {
    position: relative;
    width: 4em;
    border-radius: 4em;
    overflow: hidden;
    animation: rotate 2000ms infinite linear;
}
.rotator:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #00804d;
    border: 3px solid #fff;
    border-radius: 100%;
}
.inner-spin {
    background: #fff;
    height: 4em;
    width: 2em;
}
.inner-spin {
    animation: rotate-left 2500ms infinite cubic-bezier(0.445, 0.05, 0.55, 0.95);
    border-radius: 2em 0 0 2em;
    transform-origin: 2em 2em;
}
.inner-spin:last-child {
    animation: rotate-right 2500ms infinite cubic-bezier(0.445, 0.05, 0.55, 0.95);
    margin-top: -4em;
    border-radius: 0 2em 2em 0;
    float: right;
    transform-origin: 0 50%;
}
@keyframes rotate-left {
    60%,
    75%,
    100% {
        transform: rotate(360deg);
    }
}
@keyframes rotate {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
}
@keyframes rotate-right {
    0%,
    25%,
    45% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
}
