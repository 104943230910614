@font-face{
  font-family: Lato-Black;
  src: url(../fonts/Lato-Black.ttf);
}
@font-face{
  font-family: Lato-Bold;
  src: url(../fonts/Lato-Bold.ttf);
}
@font-face{
  font-family: Lato-Regular;
  src: url(../fonts/Lato-Regular.ttf);
}

* {
  font-family: "Lato-Regular", "Noto Sans", "Lato", "Helvetica", "Arial", "Geneve", sans-serif;
}

.text-bold {
  font-family: "Lato-Bold", "Noto Sans", "Lato", "Helvetica", "Arial", "Geneve", sans-serif;
}

.text-black {
  font-family: "Lato-Black", "Noto Sans", "Lato", "Helvetica", "Arial", "Geneve", sans-serif;
}

.navbar-nav li a {
  font-family: "Lato-Bold", "Noto Sans", "Lato", "Helvetica", "Arial", "Geneve", sans-serif;
}

.product-abstract {
  padding: 10px;
}

.product-abstract p {
  display: -webkit-box;
  margin: 0 auto;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.accessory-box p.abstract {
  display: -webkit-box;
  max-width: 400px;
  line-height: 1.4;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.accessory-box-img {
  height: auto !important;
}

.product-wrapper .main-img img {
  height: auto !important;
  max-height: 362px;
}

#error.container-fluid {
  min-height: 400px;
}

#scheda-tecnica ul li {
  font-size: 13px;
}

div#product-list .list {
  width: 100% !important;
  margin: 30px auto !important;
}

div#product-list .title {
  margin-bottom: 0 !important;
}

@media only screen and (max-width: 648px) {
  body select#lang-select {
    position: relative;
  }
}

/* SOCIAL LINK */

nav.navbar li a {
  border-bottom: 4px solid transparent;
}

.instagram-social {
  padding-right: 0px !important;
  border-left: 2px solid #ffffff;
}

.facebook-social{
  padding-right: 0px !important;
}

.lang-select {
  display: none !important;
  margin-top: 12px !important;
  color: #fff;
  padding: 10px 13.33px;
  background-color: #060c0f;
  border: 0;
}

nav.navbar li.lang {
  display: inline !important;
}

.col-6 {
  width: 50%;
  float: left;
}

.text-right {
  text-align: right;
}

.font-big {
  font-size: 25px;
}

@media only screen and (max-width: 648px) {
  .instagram-social {
    border-left: none;
    padding: initial;
  }

  .facebook-social{
    padding: initial;
  }

  .col-6 {
    width: 100%;
  }

  .text-right {
    text-align: left;
  }
}

.nav > li > a:focus {
  background-color: transparent;
  outline: none;
}

.nav > li > .instagram-social:hover,
.nav > li > .facebook-social:hover,
.nav > li > .youtube-social:hover {
  border-bottom-color: transparent;
}

.cont-lang {
  float: left;
  padding-left: 0;
  margin-top: 14px;
}

@media only screen and (max-width: 988px) {
  .cont-lang {
    margin-top: 9px;
  }
}

@media only screen and (max-width: 867px) {
  .cont-lang {
    margin-top: 6px;
  }

  nav.navbar li a {
    font-size: 12px !important;
    padding-right: 10px !important;
    padding-left: 10px !important;
  }
}

@media only screen and (max-width: 848px) {
  body #logo-nav {
    display: block !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  body .navbar li {
    float: left !important;
    text-align: center !important;
    margin-bottom: 20px !important;
  }

  .u-static {
    display: none !important;
  }

  nav.navbar li.lang {
    padding-top: 0;
  }
}

@media only screen and (max-width: 767px) {
  nav.navbar li a {
    font-size: 1.4rem !important;
    padding-right: 15px !important;
    padding-left: 15px !important;
  }

  .cont-lang {
    margin-top: 9px !important;
  }
}

@media only screen and (max-width: 648px) {
  #u-social {
    width: 25% !important;
  }

  .cont-lang {
    width: 100%;
  }

  nav.navbar li.lang {
    width: 16.6%;
  }
}

@media (max-width: 767px) {
  .navbar-header {
    float: none;
  }

  .navbar-left, .navbar-right {
    float: none !important;
  }

  .navbar-toggle {
    display: block;
  }

  .navbar-toggle .icon-bar {
    background-color: #ffffff;
  }

  .navbar-collapse {
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
  }

  .navbar-fixed-top {
    top: 0;
    border-width: 0 0 1px;
  }

  .navbar-collapse.collapse {
    display: none !important;
  }

  .navbar-nav {
    float: none !important;
    margin-top: 7.5px;
  }

  .navbar-nav > li {
    float: none;
  }

  .navbar-nav > li > a {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .collapse.in {
    display: block !important;
  }
}

@media (min-width: 767px) {
  .navbar-collapse.collapse {
    display: block !important;
    height: auto !important;
    padding: 0;
    box-shadow: none;
    border: none;
    overflow: visible !important;
  }

  .navbar-toggle {
    display: none;
  }
}

.link-default-style {
  color: #fff;
  font-size: 1.4rem;
}

.link-default-style:hover {
  color: #ccc;
  text-decoration: none;
}

.title-header {
  margin-left: 10px !important;
}

.products-box {
  border-radius:4px;
  -webkit-box-shadow: 0 0 15px 1px rgba(0,0,0,0.05);
  -moz-box-shadow: 0 0 15px 1px rgba(0,0,0,0.05);
  box-shadow: 0 0 15px 1px rgba(0,0,0,0.05);
}
.products-box iframe,
.products-box .products-img-wrapper,
.products-box .products-img{ border-top-left-radius: 4px; border-top-right-radius: 4px; }

.product-desc { min-height:400px; }

.accessory-box h4, .contact-info h2, .event-title { font-weight:400; line-height:25px; }

p { margin:0 0 15px; }

.category-carousel .jcarousel-wrapper { box-shadow: unset; border:0; }
.category-carousel .jcarousel li { padding:20px; border:0; }
.category-carousel .jcarousel-control-prev:hover,
.category-carousel .jcarousel-control-next:hover{ color:#FFF; text-decoration: none; }
.category-carousel .jcarousel-control-prev { left:25px; }
.category-carousel .jcarousel-control-next { right:25px; }
.category-carousel .jcarousel-control-prev:focus,
.category-carousel .jcarousel-control-next:focus { color:#FFF; }

footer { position:absolute; bottom:0; width:100%; z-index:1500; }
footer a, footer h3, footer h4, footer span { line-height:15px; margin-top:5px; margin-bottom:5px;  }

body #nav-products { left:10px; }

.mfp-wrap {z-index:2043 !important; }

.event-img { width:150px; height:100px; float:left; border:1px solid #aeaeaf; background-size:cover; }
.event-content { float:left; margin-left:20px; }
.event-content { max-width:calc(100% - 170px); }

.bg-header { background:#000 !important; }
#header-home { position:relative; }
#header-home .testi-wrapper { text-align:center; z-index:500; position:absolute; top:0; left:0; width:100%; padding:0 100px; }
.video-wrap { position:relative; overflow:hidden; height:65vh; width:100%; }
#video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: 0;
  -ms-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.float-none { float:none !important; }
.overflow-hidden { overflow:hidden !important; }
.fiere { overflow:hidden; }

#product-list .title-header { padding-right:10px; }

.main-content {
  position:relative;
  min-height: 1200px;
}
#nav-products { top:40px; }
#tricolore { height:6px; }

@media (min-width:768px) {
  body .clear-nav { margin-top:90px; clear:both; }
  .container-sidebar1 { margin-left:20%; width:80%; }
}


/*********************************************************************************/

.menu-contatti { border-right:2px solid #FFF; }
.navbar-header .link-social { list-style:none; float:left; margin-left:0; padding-left:0; }
.navbar-header .link-social li { display:inline; padding:5px; line-height:70px; }
.navbar-header .link-social li .instagram-social { border-left:0; }
.navbar-header .link-social li a { text-decoration: none; }
.navbar-header .link-social li a:hover { border-bottom:0; text-decoration:none; }
.navbar-header { overflow:hidden; position:relative; top:-10px; }
nav.navbar .logo img { margin-top:0; }
.navbar-header a.logo { float:left; overflow:hidden; }
.navbar-nav .cont-lang { margin-left:15px; margin-top:15px !important; }
.navbar-nav .cont-lang a { text-decoration:none; }
.navbar-collapse.collapse { margin-top:0 !important; }
.link-social-mobile { display:none; float:left; width:100%; }
nav.navbar .link-social-mobile li a:hover { border-bottom:0; }
body #nav-products { z-index:1000; }
.navbar-collapse.collapse { overflow:hidden; }

@media (min-width: 768px) {
  .navbar-collapse.collapse { float:right; line-height:40px; }
  .navbar-nav .cont-lang { margin-top:0 !important; }
  nav.navbar li.lang { padding-top:0; padding-bottom:0; position:relative; top:2px; }
  nav.navbar li.lang a { border-bottom:0; }
  .navbar-nav { margin:0; }
  .navbar-nav>li>a { padding-top:15px; padding-bottom:15px; }
  nav.navbar li { margin-bottom:0 !important; }
  nav.navbar li a { font-size:1.3rem !important; }
  nav.navbar .link-social li { float:none !important; }
  nav.navbar .link-social li a { padding-left:0 !important; padding-right:0 !important; }
}

@media (max-width: 767px) {
  .navbar-header a.logo { float:none; }
  .navbar-header .link-social { margin-top:0; margin-bottom:0; float:left; }
  .navbar-header .link-social li { line-height:30px; margin-bottom:0 !important; float:none !important; }
}

@media (max-width: 648px) {
  .menu-contatti { border-right:0; }
  .navbar-nav .cont-lang { margin-left:0; text-align:center; }
  nav.navbar li.lang { margin-bottom:0; float:none !important; text-align:center; }
  nav.navbar { min-height:91px; }
  .navbar-header { position:absolute; top:10px; width:100%; }
  .navbar-header .link-social { display:none; }
  .navbar .container-fluid { margin-bottom:0; overflow:hidden; }
  .navbar-toggle { z-index:100; }
  .navbar-collapse,
  .navbar-collapse.collapse { margin-top:70px !important; }
  .navbar .menu-principale { padding-left:20px; padding-right:20px; }
  .link-social-mobile { display:block; margin-top:0 !important; text-align:center;
    margin-bottom:10px; margin-left:0; padding-left:0; }
  body .navbar .link-social-mobile li { width:auto !important; margin-bottom:0 !important;
    float:none !important; display:inline-block; }
}

.products-description { padding:20px; padding-top:0; height:60px; line-height:20px;
  overflow:hidden; display:-webkit-box; -webkit-line-clamp:3; -webkit-box-orient:vertical; text-overflow: ellipsis; display:none; }
.products-box { padding-bottom:20px; overflow:hidden; }
.products-box .product-button { position:absolute; bottom:0; height:50px; text-align: center; display:none; width:100%; }
.products-box .product-button span { background-color:#001d11; padding:0 10px; border-radius: 4px; line-height:40px;
  margin-top:10px; display:inline-block; color:#b5fade; text-transform: uppercase; }
.products-box:hover .product-box-wrapper { top:-50px; }
.products-box:hover {
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.5);
}
div#product-list .list:hover,
.lista-prodotti:hover { text-decoration: none; }
div#product-list .list .product-box-wrapper,
.lista-prodotti .product-box-wrapper { transition:transform 0.3s ease-out; }
nav.navbar li a { text-transform: uppercase; }

nav.navbar,
footer { background:#001d11; }

.nav-prod { background-color:#001d11; }
.nav-prod h1 a,
.nav-prod h2 a,
.nav-prod .panel-title a,
.nav-prod .panel-body a { color:#b5fade; text-transform:uppercase; }
.nav-prod .panel-title a { font-size:1.5rem; }
.nav-prod h2 { font-size: 2.6rem; }

.nav-prod .panel-title a .icon-accordion { color:#001d11; }

.nav-prod h1 a:hover,
.nav-prod h1 a:focus,
.nav-prod h2 a:hover,
.nav-prod h2 a:focus,
.nav-prod .panel-title a:hover,
.nav-prod .panel-title a:focus,
.nav-prod .panel-body a:hover,
.nav-prod .panel-body a:focus { text-decoration:none; color:#fff; opacity:1; }
.nav-prod .panel-body li a:hover { text-decoration:none; color:#fff; opacity:1; }

/* .nav-prod { background-color:#b5fade; } */
.btn, button { background:#001d11; color:#b5fade; }

nav.navbar li a { color:#b5fade; }
nav.navbar li a:hover { color:#fff; opacity:1; }

.fiele-home { padding:0 15px; }
.fiele-home .event { padding:20px; }
.fiele-home .event-wrapper { width:100%; float:none; margin-left:0; padding:0; }
.fiele-home .event-img { float:none; display:block; width:100%; height:188px; background-position:center center; }
.fiele-home .event-content { margin:0; padding:10px; float:none; width:100%; max-width:100%; }
.fiele-home .event-content .event-title { margin:0; margin-bottom:10px; }

.details .video-wrapper { margin:0 auto; width:100%; float:none; padding:0; }
.details #scheda-tecnica { padding-left:40px; }

.product-section,
.details { overflow:hidden; }

.breadcrumps, .subnav { z-index:1001; }

@media (max-width: 768px) {
  .details .video-wrapper { padding-top:40px; }
  .navbar-header .link-social { display:none; }
  .products-box .product-button { position:static; display:block; }
  .products-box .product-button span { margin-top:20px; }
}

@media (min-width: 768px) {
  div#product-list .list:hover .product-box-wrapper,
  .lista-prodotti:hover .product-box-wrapper { transform:translateY(-50px); -ms-transform:translateY(-50px); }
  div#product-list .list:hover .product-button,
  .lista-prodotti:hover .product-button { display:block; bottom:-50px; }
  .product-wrapper .main-img { margin-left:0; width:100%; }
}

/*********************************************************************************/

body #nav-products { left:0; padding-left:0; }
.nav-prod .panel-title a { font-weight:normal; }
.nav-prod .panel-title a.active, .nav-prod .panel-title a:hover { font-weight:normal; }
.nav-prod .panel-title a .icon-accordion { background:transparent; color:#b5fade; }

.nav-prod .nav-prod-header {
  margin-bottom: 4px;
  margin-top: 3px;
}

.nav-prod .nav-prod-header h1 a,
.nav-prod .nav-prod-header h2 a,
.nav-prod .nav-prod-header h3 a,
.nav-prod .panel-title a {
  font-family: "Lato-Black", "Noto Sans", "Lato", "Helvetica", "Arial", "Geneve", sans-serif;
}

.nav-prod .panel-wrapper {
  margin: 4px 0;
  padding-top: 4px;
  padding-bottom: 4px;
}

.nav-prod .panel-wrapper li a {
  font-family: "Lato-Bold", "Noto Sans", "Lato", "Helvetica", "Arial", "Geneve", sans-serif;
}

.nav-prod .panel-body li a {
  font-size: 1.1rem;
}

.products-box { border-radius: 20px; }
.products-title {
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  color: #001d11;
}
.products-txt { display:table; }

#scheda-tecnica h3 { margin-top:0; }
.u-icon-next:before { font-size:1rem; font-weight:normal; }
.nav-prod .panel-title a .u-icon-next { top: 0; }

.product-desc { text-align: justify; }

#accessories .products-box {
  background-color: #ffffff;
}
